<template>
  <v-app>
    <v-main>
      <v-sheet>
        <v-container>
          <v-row>
            <v-col cols="12" sm="5" md=4 lg="3" class="mt-9">
              <v-row>
                
                <v-col>
                  <v-sheet
                    width=""
                    rounded
                    height=""
                    dark
                    elevation="5"
                    color="indigo darken-4"
                    class="mb-2"
                  >
                    <v-row justify="start">
                      <v-col cols="12">
                        <div
                          v-text="'AUDIDOC'"
                          class="headline font-weight-light ml-10"
                        ></div>
                      </v-col>
                    </v-row>
                  </v-sheet>
                </v-col>
                
              </v-row>
              <v-row>
                <v-divider class="mr-3" vertical></v-divider>
                <v-col>
                  <v-sheet rounded="" elevation="">
                    <v-container>
                      <v-list rounded>
                        <v-subheader
                          v-text="'Casos'"
                          class="header font-weight-light"
                        ></v-subheader>
                        <v-list-item-group
                          v-model="selectedItem"
                          color="primary"
                        >
                          <v-list-item v-for="(item, i) in items" :key="i">
                            <v-list-item-icon>
                              <v-icon
                                v-text="item.icon"
                                color="blue darken-4"
                              ></v-icon>
                            </v-list-item-icon>
                            <v-list-item-content
                              @click="
                                casos(item.action);
                                $store.state.titulo_bandeja_entrada = item.text;
                              "
                            >
                              <v-list-item-title
                                v-text="item.text"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-container>
                  </v-sheet>
                </v-col>
                <v-divider class="ml-n3" vertical></v-divider>
              </v-row>
            </v-col>
           
            <v-col cols="12" sm="7" md="8" lg="9">
              <router-view></router-view>
            </v-col>
          </v-row>
        </v-container>
      </v-sheet>
      <!-- <v-sheet color="blue ">
        <v-row justify="start" class="">
          <v-col cols="3">
            <v-sheet
              width=""
              rounded
              height=""
              dark
              elevation="5"
              color="indigo darken-4"
              class="mt-2 ml-4"
            >
              <v-row justify="start">
                <v-col cols="12">
                  <div
                    v-text="'AUDIDOC'"
                    class="headline font-weight-light ml-10"
                  ></div>
                </v-col>
              </v-row>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <v-sheet
              class="ml-4"
              color="white"
              elevation="3"
              rounded
              width=""
              height="600"
              id="documentos"
            >
              <v-container fluid>
                <v-row justify="start">
                  <v-container fluid>
                    <v-row class="">
                      <v-col cols="12">
                        <v-list rounded>
                          <v-subheader
                            v-text="'Opciones'"
                            class="header font-weight-light"
                          ></v-subheader>
                          <v-list-item-group
                            v-model="selectedItem"
                            color="primary"
                          >
                            <v-list-item v-for="(item, i) in items" :key="i">
                              <v-list-item-icon>
                                <v-icon
                                  v-text="item.icon"
                                  color="blue darken-4"
                                ></v-icon>
                              </v-list-item-icon>
                              <v-list-item-content
                                @click="
                                  casos(item.action);
                                  $store.state.titulo_bandeja_entrada =
                                    item.text;
                                "
                              >
                                <v-list-item-title
                                  v-text="item.text"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-row>
              </v-container>
            </v-sheet>
          </v-col>
          <v-col>
            <router-view></router-view>
          </v-col>
        </v-row>
      </v-sheet> -->
    </v-main>
  </v-app>
</template>
<script>
//import BandejaEntrada from '../../components/BandejaEntrada.vue'
//import bandeja_entrada from '../../mixins/bandeja_entrada'
//const axios = require('axios').default
export default {
  //mixins: [bandeja_entrada],
  components: {
    //BandejaEntrada
  },

  data() {
    return {
      selectedItem: 0,
      items: [
        { text: "Pendientes", icon: "mdi-file-find", action: "Pendiente" },
        { text: "Aceptados", icon: "mdi-file-find", action: "Aceptado" },
        {
          text: "Rechazados",
          icon: "mdi-file-find",
          action: "Rechazado",
        },
      ],
      /* Variables del mixin
            tokens: [],
            folders: [],
            folderPorPagina: [],
            todosLosFolder: [],
            page: 1,
           */
    };
  },
  watch: {
    /* Funciones del mixin
      page: function() {
        //console.log(this.page);
        this.folders = this.folderPorPagina[this.page - 1];
        //console.log(this.folders);
      }
     */
  },
  created() {
    this.casos("Pendiente");
  },
  methods: {
    casos(tipo) {
      if(this.$route.params.id!=tipo){
        this.$router.push({
          name: "casos_empleados",
          params: { id: tipo },
        });
      }
    },
  },
};
</script>